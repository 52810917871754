/* Base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;



/* Prevent text size adjustment on orientation change */
html {
  -webkit-text-size-adjust: 100%;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  position: fixed;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/* Allow scrolling but disable text selection */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

input[type="text"], input[type="number"], textarea {
  font-size: 16px;
}



.animate-float-heart {
  animation: floatHeart 1s forwards;
  position: absolute;
  pointer-events: none;
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}

.animate-fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes heartFadeOut {
  0% { 
    opacity: 1; 
    transform: translate(-50%, -50%) translateY(0);
  }
  100% { 
    opacity: 0; 
    transform: translate(-50%, -50%) translateY(-50px);
  }
}

.animate-heart-fade-out {
  animation: heartFadeOut 1.5s forwards;
  position: absolute;
  pointer-events: none;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.wiggle {
  animation: wiggle 0.5s ease-in-out;
}
.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

/* Interactivity styles */
.character {
  transition: transform 0.3s ease-in-out;
}

.character:active {
  transform: scale(1.05);
}

.apple {
  transition: transform 0.3s ease-in-out;
}

.apple:hover {
  transform: scale(0.7);
}

/* Utility classes */
.select-none {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.object-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.object-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}


.rounded-full {
  border-radius: 9999px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-50 {
  z-index: 50;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}



.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/* Grid layout for investment options */
.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}

/* Styles for the new footer buttons */
.rounded-full {
  border-radius: 9999px;
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.max-w-md {
  max-width: 28rem;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


/* Transition for button hover effects */
.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

/* Additional classes */
.bottom-16 {
  bottom: 4rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.h-6 {
  height: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.h-64 {
  height: 16rem;
}

/* New styles */
.w-40 {
  width: 10rem;
}

.w-32 {
  width: 8rem;
}

.h-32 {
  height: 8rem;
}

.opacity-50 {
  opacity: 0.5;
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  @keyframes hatch {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .shake-animation {
    animation: shake 0.5s ease-in-out;
  }
  
  .pulse-animation {
    animation: pulse 0.5s ease-in-out;
  }
  
  .hatch-animation {
    animation: hatch 1s ease-in-out;
  }

  @keyframes bounce {
    0%, 100% { transform: translateY(-25%); animation-timing-function: cubic-bezier(0.8, 0, 1, 1); }
    50% { transform: translateY(0); animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  }
  
  .animate-bounce {
    animation: bounce 1s infinite;
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  @keyframes pat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  .patting {
    animation: pat 0.5s ease-in-out;
  }

  .animate-float-heart {
    animation: float-heart 1s forwards;
  }
  
  @keyframes float-heart {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(var(--heart-offset-x), var(--heart-offset-y)) scale(0.5);
      opacity: 0;
    }
  }


  .apple {
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
  }
  
  .apple img {
    pointer-events: none;
  }
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulsate {
    animation: pulsate 1s ease-in-out infinite;
  }

  /* Dark mode text and icon improvements */
.dark-mode {
    --text-primary: #ffffff;
    --text-secondary: #e0e0e0;
    --icon-primary: #ffffff;
    --icon-secondary: #e0e0e0;
    --bg-primary: #121212;
    --bg-secondary: #1e1e1e;
    --bg-tertiary: #2a2a2a;
  }
  
  .dark-mode .text-indigo-700,
  .dark-mode .text-indigo-600,
  .dark-mode .text-gray-600,
  .dark-mode .text-gray-700 {
    color: var(--text-primary);
  }
  
  .dark-mode .text-indigo-800,
  .dark-mode .text-gray-500 {
    color: var(--text-secondary);
  }
  
  .dark-mode .bg-white {
    background-color: var(--bg-secondary);
  }
  
  .dark-mode .bg-indigo-600 {
    background-color: #4338ca; /* A brighter indigo for better contrast */
  }
  
  .dark-mode .bg-gray-200 {
    background-color: var(--bg-tertiary);
  }
  
  .dark-mode .bg-indigo-100 {
    background-color: #3730a3; /* A darker indigo for better contrast */
  }
  
  .dark-mode .hover\:bg-indigo-700:hover {
    background-color: #4f46e5; /* A brighter indigo for hover state */
  }
  
  .dark-mode .hover\:text-gray-100:hover {
    color: #f3f4f6; /* A very light gray for better contrast on hover */
  }
  
  /* Icon colors */
  .dark-mode .text-indigo-600 path,
  .dark-mode .text-gray-500 path {
    stroke: var(--icon-primary);
  }
  
  .dark-mode .text-red-400 path {
    stroke: #f87171; /* A brighter red for better contrast */
  }
  
  /* Button styles */
  .dark-mode button {
    background-color: #4338ca; /* Brighter background for buttons */
    color: var(--text-primary);
  }
  
  .dark-mode button:hover {
    background-color: #4f46e5; /* Even brighter on hover */
  }
  
  .dark-mode button:disabled {
    background-color: #6b7280; /* A mid-gray for disabled state */
    color: #9ca3af; /* A lighter gray for disabled text */
  }
  
  /* Input styles */
  .dark-mode input[type="text"],
  .dark-mode input[type="number"],
  .dark-mode textarea {
    background-color: var(--bg-tertiary);
    color: var(--text-primary);
    border-color: #4b5563; /* A mid-gray for borders */
  }
  
  /* Additional utility classes for dark mode */
  .dark-mode .bg-opacity-50 {
    --tw-bg-opacity: 0.7; /* Increased opacity for better visibility */
  }
  
  .dark-mode .shadow-md,
  .dark-mode .shadow-xl {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  
  /* Ensure good contrast for status indicators */
  .dark-mode .bg-green-500 {
    background-color: #10b981; /* A brighter green for better contrast */
  }
  
  .dark-mode .bg-blue-500 {
    background-color: #3b82f6; /* A brighter blue for better contrast */
  }
  
  .dark-mode .bg-yellow-400 {
    background-color: #fbbf24; /* A brighter yellow for better contrast */
  }

  .character-container {
    position: relative;
  }
  
/* Base styles for all animations and images */
.character-container video,
.character-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Default state: only show the default image */
.character-container img.default-state {
  opacity: 1;
}

.character-container video {
  opacity: 0;
}

/* Idle animation styles */
.character-container.idle video.idle-animation {
  opacity: 1;
}

.character-container.idle img.default-state {
  opacity: 0;
}
.advanced-glass-button-left {
  position: fixed;
  bottom: 100px;
  left: 20px;
  z-index: 20;
}

.advanced-glass-button-right {
  position: fixed;
  bottom: 100px;
  right: 80px;
  z-index: 20;
}
/* Patting animation styles */
.character-container.patting video.patting-animation {
  opacity: 1;
}

.character-container.patting img.default-state {
  opacity: 0;
}
/* Nom1 animation styles */
.character-container video.nom1-animation {
  opacity: 0;
  transform: translateY(-10%); /* Adjust this value to move it more or less */
}
/* Nom1 animation styles */
.character-container.nomming video.nom1-animation {
  opacity: 1;
}

.character-container.nomming img.default-state {
  opacity: 0;
}

/* Ensure other animations are hidden when not active */
.character-container:not(.idle) video.idle-animation,
.character-container:not(.patting) video.patting-animation,
.character-container:not(.nomming) video.nom1-animation {
  opacity: 0;
}

/* App container background gradients */
.app-container {
  transition: background 1.5s ease-in-out;
}

/* Dark mode backgrounds */
.dark-mode.app-container {
  background: linear-gradient(135deg, #1f1f23 0%, #452963 100%) !important;
}

.dark-mode.app-container.happy {
  background: linear-gradient(135deg, #231f23 0%, #2c1631 100%) !important;
}

.dark-mode.app-container.ecstatic {
  background: linear-gradient(135deg, #271f23 0%, #352a30 100%) !important;
}

.dark-mode.app-container.sad {
  background: linear-gradient(135deg, #1f2023 0%, #2a2c30 100%) !important;
}

.dark-mode.app-container.angry {
  background: linear-gradient(135deg, #231f1f 0%, #302a2a 100%) !important;
}

.dark-mode.app-container.neutral,
.dark-mode.app-container.bored,
.dark-mode.app-container.hungry {
  background: linear-gradient(135deg, #3e465f 0%, #2a2b30 100%) !important;
}

/* Light mode backgrounds */
.light-mode.app-container {
  background: linear-gradient(135deg, #f7fafc 0%, #edf2f7 100%) !important;
}

/* Add more light mode mood backgrounds as needed */
  
  /* Animation for patting */
  @keyframes pat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  .patting {
    animation: pat 0.5s ease-in-out;
  }
  .nom1-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .dark-mode .main-header-container::after {
    background: linear-gradient(to bottom, rgba(55,55,55,1), rgba(20,20,20,1));
    box-shadow: 0 5px 15px rgba(255,255,255,0.1);
  }
  
  /* Additional styles to enhance the 3D effect */
  .main-header-container {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.3s ease;
  }
  
  .main-header-container:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .dark-mode .main-header-container {
    background: linear-gradient(to bottom, rgba(55,55,55,1), rgba(20,20,20,1));
    box-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06);
  }
  
  .dark-mode .main-header-container:hover {
    box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1), 0 4px 6px -2px rgba(255, 255, 255, 0.05);
  }

/* Dark mode gradients */
.dark-gradient-happy,
.app-container.happy,
.dark-mode .app-container.happy {
  background: linear-gradient(135deg, #1a365d 0%, #2a4365 100%)!important;
}

.dark-gradient-sad,
.app-container.sad,
.dark-mode .app-container.sad {
  background: linear-gradient(135deg, #2d3748 0%, #4a5568 100%);
}

.dark-gradient-angry,
.app-container.angry,
.dark-mode .app-container.angry {
  background: linear-gradient(135deg, #742a2a 0%, #9b2c2c 100%);
}

.dark-gradient-neutral,
.app-container.neutral,
.dark-mode .app-container.neutral {
  background: linear-gradient(135deg, #1a202c 0%, #2d3748 100%);
}

/* Base styles for character container */
.character-container {
  position: relative;
  z-index: 1;
  background-color: #000000; /* True black background */
  width: 15rem; /* Adjust as needed */
  height: 15rem; /* Adjust as needed */
  border-radius: 50%;
  overflow: visible;
}

.character-container::after {
  content: '';
  position: absolute;
  top: -15px;  /* Reduced from -25px */
  left: -15px;  /* Reduced from -25px */
  right: -15px;  /* Reduced from -25px */
  bottom: -15px;  /* Reduced from -25px */
  border-radius: 50%;
  z-index: 0;
  opacity: 30%;  /* Reduced from 40% */
  filter: blur(15px);  /* Reduced from 20px */
  transition: box-shadow 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: none;
}

/* Mood-specific glow colors with slightly reduced intensity */
.character-container.content::after { box-shadow: 0 0 30px 20px rgba(95, 3, 255, 0.7); }
.character-container.happy::after { box-shadow: 0 0 30px 20px rgba(236, 72, 153, 0.7); }
.character-container.ecstatic::after { box-shadow: 0 0 30px 20px rgba(249, 168, 212, 0.7); }
.character-container.sad::after { box-shadow: 0 0 30px 20px rgba(96, 165, 250, 0.7); }
.character-container.angry::after { box-shadow: 0 0 30px 20px rgba(248, 113, 113, 0.7); }
.character-container.neutral::after { box-shadow: 0 0 30px 20px rgba(209, 213, 219, 0.7); }
.character-container.bored::after { box-shadow: 0 0 30px 20px rgba(156, 163, 175, 0.7); }
.character-container.hungry::after { box-shadow: 0 0 30px 20px rgba(251, 191, 36, 0.7); }

@keyframes pulsate {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 0;
    transform: scale(0.75);
  }
}

.character-container.tapped::after {
  animation: pulsate 0.3s ease-in-out;
}

/* Keep the existing styles for .character-inner-container */
.character-inner-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.character-inner-container video,
.character-inner-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 400ms ease-in-out;
}


/* App container background gradients (unchanged) */
.app-container.dark-mode {
  transition: background 1.5s ease-in-out !important;
}

.app-container.dark-mode.content {
  background: linear-gradient(135deg, #1f1f23 0%, #452963 100%) !important;
}

.app-container.dark-mode.happy {
  background: linear-gradient(135deg, #231f23 0%, #51295a 100%) !important;
}

.app-container.dark-mode.ecstatic {
  background: linear-gradient(135deg, #271f23 0%, #63314d 100%) !important;
}

.app-container.dark-mode.sad {
  background: linear-gradient(135deg, #1f2023 0%, #2a2c30 100%) !important;
}

.app-container.dark-mode.angry {
  background: linear-gradient(135deg, #231f1f 0%, #302a2a 100%) !important;
}

.app-container.dark-mode.neutral,
.app-container.dark-mode.bored,
.app-container.dark-mode.hungry {
  background: linear-gradient(135deg, #3e465f 0%, #303a70 100%) !important;
}
.nom1-video {
  transform: translateY(-20px); /* Adjust the value as needed */
}
.apple {
  transition: transform 0.5s ease-out;
}

.apple-growing {
  animation: grow 0.5s ease-out;
}

.apple-grown {
  transform: scale(1.2);
}

@keyframes grow {
  0% { transform: scale(1); }
  100% { transform: scale(1.2); }
}

.apple {
  z-index: 40; /* Increased z-index to be higher than the speech bubble */
}

.speech-bubble-container {
  z-index: 30; /* Ensure this is lower than the apple's z-index */
}

.hire-friends-screen {
  position: fixed;
  inset: 0;
  z-index: 50;
  overflow-y: auto;
}

.hire-friends-screen.dark {
  background-color: #1a202c;
  color: #fff;
}

.hire-friends-screen.light {
  background-color: #f7fafc;
  color: #1a202c;
}

.hire-friends-screen .container {
  max-width: 640px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.hire-friends-screen .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.hire-friends-screen .title {
  font-size: 1.875rem;
  font-weight: 700;
}

.hire-friends-screen .subtitle {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.hire-friends-screen .close-button {
  padding: 0.5rem;
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.hire-friends-screen.dark .close-button {
  background-color: #2d3748;
  color: #cbd5e0;
}

.hire-friends-screen.dark .close-button:hover {
  background-color: #4a5568;
}

.hire-friends-screen.light .close-button {
  background-color: #edf2f7;
  color: #4a5568;
}

.hire-friends-screen.light .close-button:hover {
  background-color: #e2e8f0;
}

.hire-friends-screen .section {
  background-color: #2d3748;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.hire-friends-screen.light .section {
  background-color: #fff;
}

.hire-friends-screen .section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.hire-friends-screen .workplace-buttons {
  display: flex;
  gap: 1rem;
}

.hire-friends-screen .workplace-button {
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.hire-friends-screen.dark .workplace-button {
  background-color: #4a5568;
  color: #cbd5e0;
}

.hire-friends-screen.dark .workplace-button:hover {
  background-color: #2d3748;
}

.hire-friends-screen.light .workplace-button {
  background-color: #edf2f7;
  color: #2d3748;
}

.hire-friends-screen.light .workplace-button:hover {
  background-color: #e2e8f0;
}

.hire-friends-screen .workplace-button.active {
  background-color: #4c51bf;
  color: #fff;
}

.hire-friends-screen .hire-buttons {
  display: flex;
  gap: 0.5rem;
}

.hire-friends-screen .hire-button {
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #4c51bf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.hire-friends-screen .hire-button:hover {
  background-color: #434190;
}

.hire-friends-screen .copy-button {
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.hire-friends-screen.dark .copy-button {
  background-color: #4a5568;
  color: #cbd5e0;
}

.hire-friends-screen.dark .copy-button:hover {
  background-color: #2d3748;
}

.hire-friends-screen.light .copy-button {
  background-color: #edf2f7;
  color: #4a5568;
}

.hire-friends-screen.light .copy-button:hover {
  background-color: #e2e8f0;
}

.hire-friends-screen .copy-button.copied {
  background-color: #48bb78;
  color: #fff;
}

.hire-friends-screen .copied-message {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #48bb78;
}

.hire-friends-screen .check-in-button {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hire-friends-screen .check-in-button.available {
  background-color: #48bb78;
  color: #fff;
}

.hire-friends-screen .check-in-button.unavailable {
  background-color: #a0aec0;
  color: #fff;
  cursor: not-allowed;
}

.hire-friends-screen .consecutive-check-ins {
  margin-left: 0.5rem;
  background-color: #ecc94b;
  color: #1a202c;
  border-radius: 9999px;
  padding: 0.25rem 0.5rem;
}

.hire-friends-screen .no-friends-message {
  text-align: center;
  color: #a0aec0;
}

.hire-friends-screen .view-referrals-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: #4c51bf;
  color: #fff;
  font-weight: 600;
  transition: background-color 0.2s;
}

.hire-friends-screen .view-referrals-button:hover {
  background-color: #434190;
}

.hire-friends-screen .inline-icon {
  vertical-align: middle;
  margin-right: 0.25rem;
}

.hire-friends-screen .button-icon {
  margin-right: 0.5rem;
}

.investment-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 50;
}

.investment-screen-content {
  min-height: 100%;
  padding: 20px;
  padding-bottom: 60px; /* Adjust this value based on your bottom UI elements */
}

.font-geist {
  font-family: 'Geist', sans-serif;
}

.text-stroke-sm {
  text-shadow: 
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}